export const heroContent = {
  en: {
    myName: ["Aaron", "Elkeslassy"],
    role: "UX | UI designer, Montreal",
  },
  fr: {
    myName: ["Aaron", "Elkeslassy"],
    role: "UX | UI designer, Montréal",
  },
};
